.search-box {
  position: relative;
  height: 40px;
}

.search-box .search-input {
  width: 100%;
  height: 100%;
  padding-right: 40px;
}

.search-box button {
  position: absolute;
  inset: 0 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  aspect-ratio: 1 / 1;
  background: none;
  color: var(--black);
  border: none;
}
